import { OneTrustCategories } from 'js/constants/TrackingConstants';

function hasConsent(cookieCategoryId) {
  if (OnetrustActiveGroups) {
    const activeGroups = OnetrustActiveGroups;
    return activeGroups.includes(cookieCategoryId);
  }
}

export const userHasPerformanceCookiesEnabled = () => hasConsent(OneTrustCategories.PERFORMANCE);
// export const userHasTargetingCookiesEnabled = () => hasConsent(OneTrustCategories.TARGETING);
// export const userHasFunctionalCookiesEnabled = () => hasConsent(OneTrustCategories.FUNCTIONAL);
// export const userHasStrictlyNecessaryCookiesEnabled = () => hasConsent(OneTrustCategories.STRICTLY_NECESSARY);
