import Header from 'js/modules/module-header';
import IntercomWrapper from 'js/modules/module-intercom';
import Accordion from 'js/modules/module-accordion';
import TrackingRM from 'js/services/trackingRM';
import Config from 'js/services/config';
import Popover from 'js/components/component-popover';
import { TOGGLE } from 'js/constants/button-constants';
import ReferralRM from 'js/services/referralRM';
import { appendTrackingToUrls } from 'js/helpers/componentHelpers';
import AlertService from 'js/services/alertService';
import TrackingGA from 'js/services/trackingGA';
import { userHasPerformanceCookiesEnabled } from 'js/helpers/oneTrustHelpers';

class Page {
  constructor($el) {
    this.$el = $el;
    this.setupTracking = this.setupTracking.bind(this);

    this.instantiateAlertService();
    this.setupComponents();
    this.setupReferrals();
    this.setupTracking();

    if (!userHasPerformanceCookiesEnabled()) {
      document.addEventListener('OptanonConsentChanged', this.setupTracking);
    }

    if (Config.showIntercom() || $el.find(`[data-${TOGGLE.INTERCOM}]`)) {
      new IntercomWrapper($el);
    }

    if ($('[data-popover]').length) {
      $('[data-popover]').each((i, ele) => {
        const $ele = $(ele);
        const popover = new Popover($ele, true);

        if ($ele.data('global')) {
          // eslint-disable-next-line no-magic-numbers
          popover.delayShow(2500);
        }
      });
    }
  }

  setupComponents() {
    const $header = this.$el.find('#js-rooster-header');
    const $accordion = this.$el.find('[data-accordion]');

    if ($header.length) {
      new Header($header);
    }

    if ($accordion.length) {
      new Accordion($accordion);
    }
  }

  setupTracking() {
    if (userHasPerformanceCookiesEnabled()) {
      this.setupRmclid();
      this.setupGaTracking();
    }
  }

  setupGaTracking() {
    TrackingGA.instantiateTrackingEvents();
  }

  setupReferrals() {
    const referral = ReferralRM.getReferral();
    const params = {};

    if (referral) {
      params.referral = referral;
    }

    appendTrackingToUrls(this.$el, params);
  }

  setupRmclid() {
    const rmclid = TrackingRM.getFromLocalStorage();
    const params = {};

    if (rmclid) {
      params.rmclid = rmclid;
      appendTrackingToUrls(this.$el, params);
    } else {
      TrackingRM.fetchRmClid((fetchedRmclid) => {
        if (fetchedRmclid) {
          params.rmclid = fetchedRmclid;
          appendTrackingToUrls(this.$el, params);
        }
      });
    }
  }

  setupReferralsAndRmclid() {
    const referral = ReferralRM.getReferral();
    const rmclid = TrackingRM.getFromLocalStorage();
    const params = {
      ...(rmclid && { rmclid }),
      ...(referral && { referral }),
    };

    if (!params.rmclid) {
      TrackingRM.fetchRmClid((rmclid) => {
        if (rmclid) {
          params.rmclid = rmclid;
        }
        appendTrackingToUrls(this.$el, params);
      });
    } else {
      appendTrackingToUrls(this.$el, params);
    }
  }

  instantiateAlertService() {
    AlertService.initialize();
  }
}

export default Page;
